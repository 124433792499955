import { ActionContext } from 'vuex';

// eslint-disable-next-line
enum EnumActionTypes {
  SET_IMAGE_LOADING = 'setImageLoading',
  SET_POINT_CLOUD_LOADING = 'setPointCloudLoading',
}

interface State {
  isImageLoading: boolean,
  pointCloudsLoadingState: { [key: number]: boolean },
}

const initialState: State = {
  isImageLoading: false,
  pointCloudsLoadingState: {},
};

const getters = {
  isImageLoading: (state: State): boolean => state.isImageLoading,
  isPointCloudLoading: (state: State) => (sceneId: number) => state.pointCloudsLoadingState[sceneId],
};

const mutations = {
  setImageLoading(state: State, isImageLoading: boolean): void {
    state.isImageLoading = isImageLoading;
  },
  setPointCloudLoading(state: State, payload: { sceneId: number, isLoading: boolean }): void {
    state.pointCloudsLoadingState[payload.sceneId] = payload.isLoading;
  },
};

const actions = {
  setImageLoading(context: ActionContext<State, Record<string, never>>, isImageLoading: boolean): void {
    context.commit('setImageLoading', isImageLoading);
  },
  setPointCloudLoading(context: ActionContext<State, Record<string, never>>, payload: { sceneId: number, isLoading: boolean }): void {
    context.commit('setPointCloudLoading', payload);
  },
};

export { EnumActionTypes };

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
