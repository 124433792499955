// Styles
import '@mdi/font/css/materialdesignicons.css';
// eslint-disable-next-line
import 'vuetify/styles';
import * as components from 'vuetify/components';

// Vuetify
import { createVuetify } from 'vuetify';

/* eslint-disable */
export default createVuetify({
  components,
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
});
