import { createStore } from 'vuex';
import geometry from './modules/geometry';
import loading from './modules/loading';
import imagePoints from './modules/image-points';
import tools from './modules/tools';

export default createStore({
  modules: {
    geometry,
    loading,
    imagePoints,
    tools,
  },
});
