/**
 * In the future, image points may be fetched from a service like WMS.
 * However, this module could be useful for prefetching data like links or nearby points
 * and check if they exist before calling the API.
 */

import { ActionContext } from 'vuex';
import { ImagePoint } from '@/models/image';

// eslint-disable-next-line
enum EnumActionTypes {
  STORE_IMAGE_POINTS = 'storeImagePoints',
}

interface State {
  imagePoints: ImagePoint[],
}

const initialState: State = {
  imagePoints: [],
};

const getters = {
  getStoredImagePoints: (state: State) => state.imagePoints,
};

const mutations = {
  storeImagePoints: (state: State, imagePoints: ImagePoint[]) => {
    state.imagePoints = imagePoints;
  },
};

const actions = {
  storeImagePoints(context: ActionContext<State, Record<string, never>>, imagePoints: ImagePoint[]): void {
    context.commit('storeImagePoints', imagePoints);
  },
};

export { EnumActionTypes };

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
