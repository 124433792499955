import { XYZ } from '@/models/geometry';
import { EnumTools } from '@/models/tool';
import { ActionContext } from 'vuex';

// eslint-disable-next-line
enum EnumActionTypes {
  SET_CURRENT_TOOL = 'setCurrentTool',
  ADD_CURRENT_TOOL_POINT = 'addCurrentToolPoint',
}

interface State {
  currentTool: EnumTools | null,
  currentToolPoints: XYZ[],
}

const initialState: State = {
  currentTool: null,
  currentToolPoints: [],
};

const getters = {
  getCurrentTool: (state: State) => state.currentTool,
  getCurrentToolPoints: (state: State) => state.currentToolPoints,
};

const mutations = {
  setCurrentTool: (state: State, currentTool: EnumTools | null) => {
    state.currentTool = currentTool;
  },
  addCurrentToolPoint: (state: State, point: XYZ) => {
    state.currentToolPoints = state.currentToolPoints.concat([point]);
  },
  resetCurrentToolPoints: (state: State) => {
    state.currentToolPoints = [];
  },
};

const actions = {
  setCurrentTool(context: ActionContext<State, Record<string, never>>, currentTool: EnumTools | null): void {
    if (!currentTool) context.commit('resetCurrentToolPoints');
    context.commit('setCurrentTool', currentTool);
  },
  addCurrentToolPoint: (context: ActionContext<State, Record<string, never>>, point: XYZ) => {
    context.commit('addCurrentToolPoint', point);
  },
};

export { EnumActionTypes };

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
