/* eslint-disable no-param-reassign */

// todo: distorted shapes at sides (not only for image but mainly for threejs objects)

import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  Euler,
  Mesh,
  SphereGeometry,
  TextureLoader,
  MathUtils,
  MeshBasicMaterial,
  CircleGeometry,
  Raycaster,
} from 'three';
import { OrbitControls } from '@three-ts/orbit-controls';
import { ImagePoint } from '@/models/image';
import { EnumActionTypes as EnumActionTypesLoading } from '@/store/modules/loading';
import { EnumActionTypes as EnumActionTypesGeometry } from '@/store/modules/geometry';
import store from '@/store';
import config from '@/config';

const getUpdateRenderFunction = (renderer: WebGLRenderer, scene: Scene, camera: PerspectiveCamera) => (
  () => {
    renderer.render(scene, camera);
    store.commit('geometry/setOrientation', camera.rotation.toArray());
  }
);

const updateViewerSize = (
  containerElem: HTMLElement,
  renderer: WebGLRenderer,
  scene: Scene,
  camera: PerspectiveCamera,
) => {
  const width = containerElem.clientWidth;
  const height = containerElem.clientHeight;
  renderer.setSize(width, height);
  camera.aspect = width / height;
  camera.updateProjectionMatrix();

  getUpdateRenderFunction(renderer, scene, camera)();
};

const initScene = () => {
  const containerElem = document.getElementById('equirectangularContainer') || {} as HTMLElement;
  if (!containerElem) throw new Error('could not find container');

  const scene = new Scene();

  const camera = new PerspectiveCamera(75, containerElem.clientWidth / containerElem.clientHeight, 0.1, 1000);
  camera.rotation.reorder('ZYX'); // important to get heading (angle around Z axis)
  camera.up.set(0, 0, 1);

  const renderer = new WebGLRenderer({ antialias: true });
  renderer.setClearColor('#dfdfdf');
  renderer.setSize(containerElem.clientWidth, containerElem.clientHeight);
  containerElem.appendChild(renderer.domElement);

  const orbitControls = new OrbitControls(camera, renderer.domElement);
  orbitControls.enablePan = false;
  orbitControls.enableZoom = false;
  orbitControls.addEventListener('change', getUpdateRenderFunction(renderer, scene, camera));
  orbitControls.update();

  getUpdateRenderFunction(renderer, scene, camera)();

  return {
    containerElem,
    scene,
    renderer,
    camera,
    orbitControls,
  };
};

const addImage = (
  prevImage: Mesh<SphereGeometry, MeshBasicMaterial> | null,
  imagePoint: ImagePoint,
  scene: Scene,
  renderer: WebGLRenderer,
  camera: PerspectiveCamera,
  orbitControls: OrbitControls,
) => {
  orbitControls.enabled = false;

  const {
    image,
    x,
    y,
    alt,
    yaw,
    pitch,
    roll,
  } = imagePoint;

  const baseUrl = 'data/equirectangular/';
  const url = `${baseUrl}${image}`;

  camera.position.set(x, y, alt);
  orbitControls.target.set(x, y + 0.0000001, alt);

  const geometry360 = new SphereGeometry(500, 60, 40);
  geometry360.scale(-1, 1, 1);

  const textureLoader = new TextureLoader();
  const texture = textureLoader.load(
    url,
    async () => {
      getUpdateRenderFunction(renderer, scene, camera)();
      await store.dispatch(`loading/${EnumActionTypesLoading.SET_IMAGE_LOADING}`, false);
    },
    (xhr) => console.log(`${((xhr.loaded / xhr.total) * 100)} % loaded`),
    () => console.log('an error happened'),
  );

  const material360 = new MeshBasicMaterial({ map: texture });

  if (prevImage) scene.remove(prevImage);

  const image360 = new Mesh(geometry360, material360);
  image360.position.set(x, y, alt);

  // something different happens here: https://github.com/potree/potree/blob/develop/src/modules/Images360/Images360.js#L135
  // must be checked!! todo
  const euler = new Euler(
    MathUtils.degToRad(-roll + 90),
    MathUtils.degToRad(+pitch),
    MathUtils.degToRad(-yaw - 90),
    'ZYX',
  );
  image360.setRotationFromEuler(euler);

  scene.add(image360);

  orbitControls.enabled = true;

  orbitControls.update();
  getUpdateRenderFunction(renderer, scene, camera)();

  return image360;
};

const addLinks = (scene: Scene, linkData: ImagePoint[] | null, prevLinks: Mesh<CircleGeometry, MeshBasicMaterial>[] | null) => {
  if (prevLinks) {
    prevLinks.forEach((x) => {
      scene.remove(x);
    });
  }
  if (!linkData) return null;

  const links: Mesh<CircleGeometry, MeshBasicMaterial>[] = [];

  linkData.forEach((x) => {
    const geometry = new CircleGeometry(0.3, 64);
    const material = new MeshBasicMaterial({ color: 'orange', transparent: true, opacity: 0.4 });
    const link = new Mesh(geometry, material);
    link.name = 'link';
    link.userData.image = x.image;
    link.position.set(x.x, x.y, x.alt - config.cameraHeight);

    links.push(link);
    scene.add(link);
  });

  return links;
};

const getIntersectionWithObject = (
  e: MouseEvent,
  containerElem: HTMLElement,
  scene: Scene,
  camera: PerspectiveCamera,
  raycaster: Raycaster,
) => {
  const clientX = e.clientX - (e.target as HTMLElement)!.getBoundingClientRect().left;
  const clientY = e.clientY - (e.target as HTMLElement)!.getBoundingClientRect().top;
  const x = (clientX / containerElem.clientWidth) * 2 - 1;
  const y = -(clientY / containerElem.clientHeight) * 2 + 1;

  raycaster.setFromCamera({ x, y }, camera);
  const intersections = raycaster
    .intersectObjects(scene.children, false)
    .filter((intersection) => intersection.object.name === 'link');

  const intersection = intersections.length > 0 ? intersections[0] : null;

  return intersection;
};

const handleMouseMove = (
  e: MouseEvent,
  links: Mesh<CircleGeometry, MeshBasicMaterial>[] | null,
  containerElem: HTMLElement,
  scene: Scene,
  renderer: WebGLRenderer,
  camera: PerspectiveCamera,
  raycaster: Raycaster,
) => {
  if (!links) return;

  links.forEach((x) => {
    x.material.color.set('orange');
  });
  containerElem.style.cursor = 'default';

  const intersection = getIntersectionWithObject(e, containerElem, scene, camera, raycaster);

  if (intersection) {
    const link = intersection.object as Mesh<CircleGeometry, MeshBasicMaterial>;
    link.material.color.set('orangered');
    containerElem.style.cursor = 'pointer';
  }

  renderer.render(scene, camera);
};

const handleClick = (
  e: MouseEvent,
  links: Mesh<CircleGeometry, MeshBasicMaterial>[] | null,
  containerElem: HTMLElement,
  scene: Scene,
  camera: PerspectiveCamera,
  raycaster: Raycaster,
) => {
  if (!links) return;

  const intersection = getIntersectionWithObject(e, containerElem, scene, camera, raycaster);

  if (!intersection) return;
  const { image } = intersection.object.userData;
  store.dispatch(`geometry/${EnumActionTypesGeometry.SET_IMAGE_POINT}`, image);
};

export {
  initScene,
  addImage,
  addLinks,
  getUpdateRenderFunction,
  updateViewerSize,
  handleMouseMove,
  handleClick,
};
