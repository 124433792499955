import proj4 from 'proj4';
import { LonLatZ, XYZ } from '@/models/geometry';
import { ImagePoint, ImagePointGeographic } from '@/models/image';

const computeCartesianCentroidFromGeographicPoints = (points: LonLatZ[], cartesianSystemProjectionString: string): XYZ => {
  const pointsCartesian: XYZ[] = points.map((p) => ([
    ...proj4('EPSG:4326', cartesianSystemProjectionString, [p[0], p[1]]) as [number, number],
    p[2],
  ]));

  const x = pointsCartesian.map((p) => p[0]).reduce((p, c) => (p + c), 0) / points.length;
  const y = pointsCartesian.map((p) => p[1]).reduce((p, c) => (p + c), 0) / points.length;
  const z = pointsCartesian.map((p) => p[2]).reduce((p, c) => (p + c), 0) / points.length;

  return [x, y, z];
};

const computeCartesianlPointCloudTranslationForLocalReferenceSystem = (pointCloudReference: LonLatZ, globalReference: XYZ, cartesianSystemProjectionString: string): XYZ => {
  const pointCloudReferenceCartesian: XYZ = [
    ...proj4('EPSG:4326', cartesianSystemProjectionString, [pointCloudReference[0], pointCloudReference[1]]) as [number, number],
    pointCloudReference[2],
  ];

  const x = pointCloudReferenceCartesian[0] - globalReference[0];
  const y = pointCloudReferenceCartesian[1] - globalReference[1];
  const z = pointCloudReferenceCartesian[2] - globalReference[2];

  return [x, y, z];
};

const absoluteCartesianToLocalCartesian = (point: XYZ, globalReference: XYZ): XYZ => (
  [
    point[0] - globalReference[0],
    point[1] - globalReference[1],
    point[2] - globalReference[2],
  ]
);

const imagePointsWithLocalCartesianCoords = (imagePoints: ImagePointGeographic[], globalReference: XYZ, cartesianSystemProjectionString: string): ImagePoint[] => {
  const imagePointsWithXY = imagePoints.map((x) => {
    const [xAbsolute, yAbsolute] = proj4('EPSG:4326', cartesianSystemProjectionString, [x.lon, x.lat]);
    const localXYZ = absoluteCartesianToLocalCartesian(
      [xAbsolute, yAbsolute, x.alt],
      globalReference,
    );

    return {
      image: x.image,
      lat: x.lat,
      lon: x.lon,
      x: localXYZ[0],
      y: localXYZ[1],
      alt: localXYZ[2],
      yaw: x.yaw,
      pitch: x.pitch,
      roll: x.roll,
      links: x.links,
    };
  });

  return imagePointsWithXY;
};

export {
  computeCartesianCentroidFromGeographicPoints,
  computeCartesianlPointCloudTranslationForLocalReferenceSystem,
  absoluteCartesianToLocalCartesian,
  imagePointsWithLocalCartesianCoords,
};
