import { Config } from '@/models/config';

export default {
  pointClouds: [
    {
      directory: 'merged2216',
      // directory: 'http://5.9.65.151/mschuetz/potree/resources/pointclouds/helimap/360/MLS_drive1',
      // referenceOverride: '-19.9179962,32.1244863,0',
    },
  ],
  cartesianSystemProjectionString: '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs',
  cameraHeight: 2,
} as Config;
