import { ActionContext } from 'vuex';
import { ImagePoint } from '@/models/image';
import { XYZ } from '@/models/geometry';
import { PointCloudData } from '@/models/point-cloud';

// eslint-disable-next-line
enum EnumActionTypes {
  SET_REFERENCE_GLOBAL = 'setReferenceGlobal',
  SET_POINTCLOUD_DATA = 'setPointCloudData',
  SET_IMAGE_POINT = 'setImagePoint',
  SET_ORIENTATION = 'setOrientation',
}

interface State {
  referenceGlobal: XYZ | null,
  pointCloudData: PointCloudData[] | null,
  imagePoint: ImagePoint | null,
  links: ImagePoint[] | null,
  viewOrientation: number[] | null,
}

const initialState: State = {
  referenceGlobal: null,
  pointCloudData: null,
  imagePoint: null,
  links: null,
  viewOrientation: null,
};

const getters = {
  getReferenceGlobal: (state: State): XYZ | null => state.referenceGlobal,
  getPointCloudData: (state: State): PointCloudData[] | null => state.pointCloudData,
  getImagePoint: (state: State): ImagePoint | null => state.imagePoint,
  getOrientation: (state: State): number[] | null => state.viewOrientation,
  getStoredImagePoints: (state: State, g: any, rootState: Record<string, never>, rootGetters: Record<string, never>) => rootGetters['imagePoints/getStoredImagePoints'] as ImagePoint[],
  getLinks: (state: State): ImagePoint[] | null => state.links,
};

const mutations = {
  setReferenceGlobal(state: State, referenceGlobal: XYZ): void {
    state.referenceGlobal = referenceGlobal;
  },
  setPointCloudData(state: State, pointCloudData: PointCloudData[]): void {
    state.pointCloudData = pointCloudData;
  },
  setImagePoint(state: State, imagePoint: ImagePoint): void {
    state.imagePoint = imagePoint;
  },
  setOrientation(state: State, orientation: number[]): void {
    state.viewOrientation = orientation;
  },
  setImageLinks(state: State, links: ImagePoint[] | null): void {
    state.links = links;
  },
};

const actions = {
  setReferenceGlobal(context: ActionContext<State, Record<string, never>>, referenceGlobal: XYZ): void {
    context.commit('setReferenceGlobal', referenceGlobal);
  },
  setPointCloudData(context: ActionContext<State, Record<string, never>>, pointCloudData: PointCloudData[]): void {
    context.commit('setPointCloudData', pointCloudData);
  },
  setImagePoint(context: ActionContext<State, Record<string, never>>, image: string): void {
    const imagePoint = getters.getStoredImagePoints(context.state, context.getters, context.rootState, context.rootGetters)
      .find((x) => x.image === image);

    if (!imagePoint) return;
    context.commit('setImagePoint', imagePoint);
    actions.setImageLinks(context, imagePoint);
  },
  setImageLinks(context: ActionContext<State, Record<string, never>>, imagePoint: ImagePoint): void {
    const { links } = imagePoint;
    if (!links) {
      context.commit('setImageLinks', null);
      return;
    }

    const linkPoints = getters.getStoredImagePoints(context.state, context.getters, context.rootState, context.rootGetters)
      .filter((x) => links.includes(x.image));

    context.commit('setImageLinks', linkPoints);
  },
};

export { EnumActionTypes };

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
